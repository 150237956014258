import styles from './ModalTextWrap.module.scss';

function ModalTextWrap({ title, children }) {
  return (
    <>
      <h2 className={styles.modalTitle}>{title}</h2>
      <div className={styles.modalContent}>{children}</div>
    </>
  );
}

export default ModalTextWrap;
