import React, { useRef, useState } from 'react';
import Container from 'components/container/Container';
import FormModal from 'components/formModal/FormModal';
import ButtonClose from 'components/buttonClose/ButtonClose';
import Modal from 'components/modal/Modal';
import Section from 'components/section/Section';
import Button from 'components/button/Button';
import HeroImg from 'images/hero.webp';
import styles from './Hero.module.scss';

function Hero() {
  const modalRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Section>
      <Container>
        <h1 className={styles.title}>
          Begin an Infinite Journey of Excitement!
        </h1>
        <div className={styles.thumb}>
          <div className={styles.imageWrap}>
            <img src={HeroImg} alt="Honey Rush 100" />
          </div>

          <div className={styles.btnContainer}>
            <Button
              variant="btnHero"
              title={'PLAY'}
              onClick={handleButtonClick}
            />
          </div>
        </div>

        {isModalOpen && (
          <Modal
            ref={modalRef}
            activeModal="formmodal"
            onCloseModal={handleCloseModal}
            setIsModalOpen={setIsModalOpen}
          >
            <ButtonClose onClick={handleCloseModal} />
            <FormModal />
          </Modal>
        )}
      </Container>
    </Section>
  );
}

export default Hero;
