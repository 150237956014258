import Button from 'components/button/Button';
import styles from './AgeModal.module.scss';

function AgeModal({ onClose }) {
  const handleButtonClick = () => {
    onClose();
  };

  const handleNoButtonClick = () => {
    // Reload the page when "NO" button is clicked
    window.location.reload();
  };

  return (
    <>
      <div className={styles.text}>
        <p>
          Welcome to ninecasinoslot.com! Explore an array of enthralling games
          intended for those 18 years or older. Enjoy a realm free of real money
          involvement – pure fun awaits!
          <span>Proceed?</span>
        </p>
      </div>
      <div className={styles.buttons}>
        <Button title={'YES'} onClick={handleButtonClick}></Button>
        <Button
          variant="btnNo"
          title={'NO'}
          onClick={handleNoButtonClick}
        ></Button>
      </div>
    </>
  );
}

export default AgeModal;
