import { SetCookies } from 'helpers/Cookies';
import Button from 'components/button/Button';
import styles from './CookiesModal.module.scss';

const CookiesModal = ({ showCookies }) => {
  function checkCookie() {
    showCookies(false);
    SetCookies('ninecasinoslot', JSON.stringify(true));
  }

  return (
    <>
      <div className={styles.cookieBar}>
        <div className={styles.content}>
          <p className={styles.text}>
            To enhance your experience, ninecasinoslot.com uses cookies.
          </p>
        </div>

        <Button title={'OK'} onClick={() => checkCookie(false)} />
      </div>
    </>
  );
};

export default CookiesModal;
