import {
  TbBrandFacebook,
  TbBrandPinterest,
  TbBrandInstagram,
} from 'react-icons/tb';
import styles from './Social.module.scss';

function Social() {
  const socialMediaData = [
    {
      icon: <TbBrandPinterest />,
      link: 'https://www.pinterest.com',
      label: 'Pinterest',
    },
    {
      icon: <TbBrandFacebook />,
      link: 'https://www.facebook.com',
      label: 'Facebook',
    },
    {
      icon: <TbBrandInstagram />,
      link: 'https://www.instagram.com/',
      label: 'Instagram',
    },
  ];

  return (
    <ul>
      {socialMediaData.map(({ icon, link, label }) => (
        <li key={label}>
          <a
            href={link}
            aria-label={label}
            target="_blank"
            rel="noreferrer"
            className={styles.social__link}
          >
            {icon} <span>{label}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Social;
