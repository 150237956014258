import { MdOutlineCloseFullscreen } from 'react-icons/md';
import styles from './ButtonChangeScreen.module.scss';

function ButtonChangeScreen({ isFullscreen, setIsFullscreen }) {
  const handleFullscreenClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <button
      type="button"
      onClick={handleFullscreenClick}
      className={styles.btn}
    >
      <MdOutlineCloseFullscreen size={36} />
    </button>
  );
}

export default ButtonChangeScreen;
