import { useState, useEffect } from 'react';

// to detect user's prefers-color-scheme
function getPreferredColorScheme() {
  return window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}

// to determine the theme based on the preferred color scheme
export const useColorScheme = () => {
  const [theme, setTheme] = useState(getPreferredColorScheme);

  // Update the theme when the color scheme changes
  useEffect(() => {
    const handleChange = () => {
      setTheme(getPreferredColorScheme());
    };

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleChange);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleChange);
    };
  }, []);

  return theme;
};
