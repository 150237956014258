import React, { useState, useRef } from 'react';
import Social from 'components/social';
import Container from 'components/container/Container';
import Terms from 'components/footerModals/Terms';
import Privacy from 'components/footerModals/Privacy';
import ContactForm from 'components/contactForm/ContactForm';
import Modal from 'components/modal/Modal';
import ButtonClose from 'components/buttonClose/ButtonClose';
import { ReactComponent as Icon18 } from 'images/18-plus-white.svg';
import styles from './Footer.module.scss';

function Footer() {
  const [activeModal, setActiveModal] = useState(null);
  const modalRef = useRef();

  const openModal = modalName => {
    setActiveModal(modalName);
    modalRef.current.open();
  };

  const closeModal = () => {
    setActiveModal(null);
    modalRef.current.close();
  };

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.footerContainer}>
            <div className={styles.top}>
              <div>
                <Icon18 className={styles.icon18} />
                <p>
                  Indulge in social games that are entirely detached from
                  real-money gaming or betting activities.
                </p>
              </div>
              <div>
                <ul className={styles.footerLinks}>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('terms')}
                    >
                      <span>Terms & Conditions</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('privacy')}
                    >
                      <span>Privacy Policy</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={styles.link}
                      type="button"
                      onClick={() => openModal('contact')}
                    >
                      <span>Contact Us</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <Social />
              </div>
            </div>

            <div className={styles.bottom}>
              ninecasinoslot&copy;2024 All rights reserved
            </div>
          </div>
        </Container>
      </footer>
      <Modal ref={modalRef} activeModal={activeModal}>
        <ButtonClose onClick={closeModal} />
        {activeModal === 'terms' && <Terms />}
        {activeModal === 'privacy' && <Privacy />}
        {activeModal === 'contact' && <ContactForm />}
      </Modal>
    </>
  );
}

export default Footer;
