import ModalTextWrap from 'components/modalTextWrap/ModalTextWrap';

function Privacy() {
  return (
    <ModalTextWrap title={'Privacy Policy'}>
      <ol>
        <li>
          <h3>Introduction</h3>
          <p>
            This Privacy Policy outlines how ninecasinoslot.com collects, uses,
            maintains, and discloses information collected from users.
          </p>
        </li>
        <li>
          <h3>Personal Identification Information</h3>
          <p>
            We collect email addresses from users who wish to play our games.
            This information is used solely for providing access to our games
            and communicating with users regarding our services.
          </p>
        </li>
        <li>
          <h3>Non-Personal Identification Information</h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. This may include browser name,
            type of computer, and technical information about users' means of
            connection to our site.
          </p>
        </li>
        <li>
          <h3>Cookies Policy</h3>
          <p>
            ninecasinoslot.com uses "cookies" to enhance User experience. Users'
            web browsers place cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies or to alert them
            when cookies are being sent.
          </p>
        </li>
        <li>
          <h3>Use of Collected Information</h3>
          <p>
            ninecasinoslot.com may use Users' personal information to operate
            and improve our website and services.
          </p>
        </li>
        <li>
          <h3>Compliance with Children's Online Privacy Protection Act</h3>
          <p>
            Protecting the privacy of the very young is especially important.
            For that reason, ninecasinoslot.com never collects or maintains
            information at our Site from those we actually know are under 18.
          </p>
        </li>
        <li>
          <h3>Changes to This Privacy Policy</h3>
          <p>
            ninecasinoslot.com has the discretion to update this privacy policy
            at any time. We encourage Users to frequently check this page for
            any changes.
          </p>
        </li>
        <li>
          <h3>Your Acceptance of These Terms</h3>
          <p>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site.
          </p>
        </li>
      </ol>
    </ModalTextWrap>
  );
}

export default Privacy;
