import ModalTextWrap from 'components/modalTextWrap/ModalTextWrap';

function Terms() {
  return (
    <ModalTextWrap title={'Terms and Conditions'}>
      <ol>
        <li>
          <h3>Introduction</h3>
          <p>
            Welcome to ninecasinoslot.com. These Terms and Conditions govern
            your use of ninecasinoslot.com and its free social gaming services.
            By accessing our website, you agree to these Terms and Conditions in
            full.
          </p>
        </li>
        <li>
          <h3>User Eligibility</h3>
          <p>
            The services of ninecasinoslot.com are intended solely for users who
            are eighteen (18) years of age or older. By using our services, you
            represent and warrant that you are at least 18 years old.
          </p>
        </li>
        <li>
          <h3>Account Registration</h3>
          <p>
            To access our games, users are required to provide a valid email
            address. No formal account creation is necessary. By providing your
            email, you agree to receive communications from us related to the
            services of ninecasinoslot.com.
          </p>
        </li>
        <li>
          <h3>Service Use and Restrictions</h3>
          <p>
            Users are permitted to use our services for personal, non-commercial
            purposes. Any use of ninecasinoslot.com in connection with
            real-money gaming or gambling is strictly prohibited.
          </p>
        </li>
        <li>
          <h3>Intellectual Property Rights</h3>
          <p>
            All content on ninecasinoslot.com, including games, graphics, and
            text, is the property of ninecasinoslot.com or its licensors and is
            protected by intellectual property laws.
          </p>
        </li>
        <li>
          <h3>Limitation of Liability</h3>
          <p>
            ninecasinoslot.com will not be liable for any damages arising out of
            or related to your use of the website.
          </p>
        </li>
        <li>
          <h3>Modification of Terms</h3>
          <p>
            We reserve the right to modify these Terms and Conditions at any
            time. Continued use of the service after such changes will
            constitute your consent to such changes.
          </p>
        </li>
        <li>
          <h3>Governing Law</h3>
          <p>
            These Terms and Conditions shall be governed by Your Country's Law.
          </p>
        </li>
      </ol>
    </ModalTextWrap>
  );
}

export default Terms;
